<template>
    <div id="app">
        <div class="banner w100">
            <div class="box w100" style=" position:absolute; z-index:999">
                <div class="top">
                    <span>
                        <img v-if="oldman.smallPhoneUrl" :src="oldman.smallPhoneUrl" width="30">
                        <img v-else src="images/logo.png" width="30"></span><span>{{ oldman.userName }}
                    </span>
                    <span style="float: right">电量：{{oldman.power}}%</span>
                </div>
            </div>
            <div class="box w100">
                <a href="#"><img src="img/banner.jpg" width="100%"></a>
            </div>
        </div>
        <div style="background: #ffffff">
            <div class="clear"></div>
            <div class="bk12"></div>
            <div class="list_day" style="margin-top: 5px">
                <ul>
                    <li style="width: 95%; font-size: medium; height: auto">
                        <div class="tit" v-if="oldman.xy">
                            <span><img src="img/wz.png" width="20" style="margin-top: 5px"></span>
                            <span>当前位置</span><a :href="oldman.xy"><img :src="oldman.xy" width="100%"></a>
                        </div>
                        <div class="tit" v-else>
                            <span><img src="img/wz.png" width="20" style="margin-top: 5px"></span>
                            <span>当前位置</span><span>家</span>
                        </div>
                    </li>
                    <li style="width: 95%;font-size: medium">
                        <div class="tit">
                            <span><img src="img/xy.png" width="20" style="margin-top: 5px"></span>
                            <span style="font-size: large">血压</span>
                            <!--<button class="myButton" style="float: right;margin-right: 5px;"
                                    @click="remote('bloodpressuredata')">远程测量</button>-->

                        </div>
                        <hr color=#F5F5F5>
                        <div class="txt">
                            <div class="f" style="line-height:3rem;">血压：<span style="color: #fd7d3c;font-size:large;font-weight: bold">{{this.bloodpressuredata.dbp ? this.bloodpressuredata.dbp : '0'}}/{{this.bloodpressuredata.sbp ? this.bloodpressuredata.sbp : '0'}}</span>&nbsp;mmHg
                                <a href="/#/Bloodhistory">
                                    <button class="myButton" style="float: right;margin-top:20px;margin-right: 5px;">历史记录</button>
                                </a>
                            </div>
                            <div class="f">时间：{{new Date(this.bloodpressuredata.created_at.$date-8*60*60*1000).Format("yyyy-MM-dd hh:mm:ss")}}
                            </div>
                            <div class="f" style="line-height:2rem;font-size: small">注：手环非医疗设备，数据仅供参考，不能作为用药指导。</div>
                        </div>
                    </li>
                    <li style="width: 95%;font-size: medium">
                        <div class="tit">
                            <span><img src="img/xl.png" width="20" style="margin-top: 5px"></span>
                            <span style=";font-size: large">心率</span>
                            <!--<button class="myButton" style="float: right;margin-right: 5px;"
                                    @click="remote('heartratedata')">远程测量</button>-->

                        </div>
                        <hr color=#F5F5F5>
                        <div class="txt">
                            <div class="f" style="line-height:3rem;">心率：<span style="color: #fd7d3c;font-size: large;font-weight: bold">{{this.heartratedata.heartrate ? this.heartratedata.heartrate : '0'}}</span>&nbsp;bpm
                                <a href="/#/Hearthistory">
                                    <button class="myButton" style="float: right;margin-top:20px;margin-right: 5px;">历史记录
                                    </button>
                                </a>
                            </div>
                            <div class="f">时间：{{new Date(this.heartratedata.created_at.$date-8*60*60*1000).Format("yyyy-MM-dd hh:mm:ss")}}
                            </div>
                            <div class="f" style="line-height:2rem;font-size: small">注：手环非医疗设备，数据仅供参考，不能作为用药指导。
                            </div>
                        </div>
                    </li>
                    <li style="width: 45%;">
                        <div class="tit">
                            <span><img src="img/zl.png" width="20" style="margin-top: 5px"></span><span>步数 {{new Date().Format("yyyy-MM-dd")}}</span>
                        </div>
                        <hr color=#F5F5F5>
                        <div class="txt">
                            <!--<div class="f" style="line-height:2rem">总步数：<span style="color: orange;font-size: large;font-weight: bold">{{this.pedometerdata.value}}</span>&nbsp;步</div>-->
                            <div class="f" style="line-height:2rem">总步数：<span style="color: orange;font-size: large;font-weight: bold">0</span>&nbsp;步</div>
                            <div class="f" style="line-height:2rem">卡路里：<span style="color: orange;font-size: large;font-weight: bold">0.00</span>&nbsp;千卡</div>
                            <div class="f" style="line-height:2rem">距离：<span style="color: orange;font-size: large;font-weight: bold">0.00</span>&nbsp;千米</div>
                        </div>
                    </li>
                    <li style="width: 45%;float: right">
                        <div class="tit">
                            <span><img src="img/sleep.png" width="20" style="margin-top: 5px"></span>
                            <span>睡眠 {{new Date().Format("yyyy-MM-dd")}}</span>
                        </div>
                        <hr color=#F5F5F5>
                        <div class="txt">
                            <div class="f" style="line-height:2rem">时长：<span
                                    style="color: orange;font-size: large;font-weight: bold">0</span>&nbsp;小时&nbsp;
                            </div>
                            <div class="f" style="line-height:2rem">深睡：<span
                                    style="color: orange;font-size: large;font-weight: bold">0</span>&nbsp;小时&nbsp;
                            </div>
                            <div class="f" style="line-height:2rem">浅睡：<span
                                    style="color: orange;font-size: large;font-weight: bold">0</span>&nbsp;小时&nbsp;
                            </div>
                        </div>
                    </li>
                </ul>

            </div>
        </div>
    </div>
</template>
<script>
    import {getMyoldmanInfo, getTestData, remoteTest} from '../api/getMyoldman'

    export default {
        name: "Wristband",
        created() {
            //window.ts.Toast.text("openid:"+sessionStorage.getItem('openId'))
            getMyoldmanInfo(sessionStorage.getItem('openId'), new Date().Format("yyyy-MM-dd")).then(res => {
                this.myoldman = res.data.data
                this.oldman = this.myoldman[sessionStorage.getItem("ind") ? sessionStorage.getItem("ind") : 0]
            })
            let id = this.$route.query.deviceId
            getTestData(id, 'heartratedata').then(res => {
                this.heartratedata = res.data.objs[0]
                window.heart = res.data.objs
            }),
            getTestData(id, 'bloodpressuredata').then(res => {
                this.bloodpressuredata = res.data.objs[0]
                window.blood = res.data.objs
            }),
            getTestData(id, 'pedometerdata').then(res => {
                this.pedometerdata = res.data.objs[0]
                window.steps = res.data.objs
                //window.ts.Toast.text("steps:"+this.pedometerdata.value)
            }),
            getTestData(id, 'sleepdata').then(res => {
                this.sleepdata = res.data.objs[0]
                window.sleep = res.data.objs
            })
        },
        data() {
            return {
                oldman: sessionStorage.getItem("oldman") ? JSON.parse(sessionStorage.getItem("oldman")) : window.oldman,
                heartratedata: "",
                bloodpressuredata: "",
                pedometerdata: "",
                sleepdata: ""
            }
        },
        methods: {
            remote(type) {
                let id = this.$route.query.deviceId
                window.ts.Toast.text("type:"+type)
                var dt = false
                remoteTest(id, type).then(res => {
                    dt = res.data.success
                    if (dt) {
                        alert("远程测量成功，请稍后刷新页面查看！")
                    } else {
                        alert("设备已离线！")
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .myButton {
        box-shadow: 0px 0px 0px 2px #fce2c1;
        /*background: linear-gradient(to bottom, #ffc477 5%, #fb9e25 100%);*/
        background-color: #fd7d3c; /*#ffc477;*/
        border-radius: 5px;
        border: none; /*1px solid #eeb44f;*/
        display: inline-block;
        cursor: pointer;
        color: white; /*#ffffff;*/
        font-family: Arial;
        font-size: 13px;
        padding: 5px 15px;
        text-decoration: none;
        /*text-shadow: 0px 1px 0px #cc9f52;*/
    }

    .myButton:hover {
        background: linear-gradient(to bottom, #fb9e25 5%, #ffc477 100%);
        background-color: #fb9e25;
    }

    .myButton:active {
        position: relative;
        top: 1px;
    }

</style>