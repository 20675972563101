<template>
    <div id="app">
        <ol>
            <li v-if="oldman">
                <div class="clear"></div>
                <div class="bk12"></div>
                <div class="clear"></div>
                <div class="bk12"></div>

                <div class="list_wd">
                    <ul>
                        <li><span><a href="#">照片</a></span><span class="rt"><a
                                href="#"><img src="img/next.png"></a></span><span class="rt">
                            <nut-uploader
                                    name="uploader"
                                    :url="url"
                                    :isPreview="true"
                                    :acceptType="['image/jpeg', 'image/png', 'image/gif', 'image/bmp']"
                                    @start="onStart"
                                    @success="onSuccess"
                                    @fail="onFail"
                                    @progress="onProgress"
                                    @preview="onPreview"
                                    @showMsg="showMsgFn"
                                    typeError="对不起，不支持上传该类型文件！"
                                    limitError="对不起，文件大小超过限制！"
                                    :attach="formData"
                            >
                            <!--<img
                                    v-if="oldman.bigPhotoUrl" :src="oldman.bigPhotoUrl"
                                    width="60"><img v-else src="images/logo.png"
                                                    width="60">-->
                            </nut-uploader>
                        </span></li>
                        <li @click="dialogShow=true"><span>姓名</span><span class="rt"><a
                                href="#"><img src="img/next.png"></a></span><span
                                class="rt">{{oldman.userName}}
                        <nut-dialog title="修改姓名" :visible="dialogShow" @ok-btn-click="userName"
                                    @cancel-btn-click="reloadusername" @close="dialogShow=false">
                                    <nut-textinput
                                            v-model="oldman.userName"
                                            placeholder="请输入内容"
                                            :clearBtn="true"
                                            :disabled="false"
                                    />
                        </nut-dialog>
                        </span></li>
                        <li @click="switchActionSheet"><span>性别</span><span class="rt"><a
                                href="#"><img src="img/next.png"></a></span><span
                                class="rt"><span class="selected-option">{{sex}}</span>
                           </span></li>
                        <nut-actionsheet :is-visible="isVisible"
                                         @close="switchActionSheet"
                                         :menu-items="menuItems"
                                         @choose="chooseItem"
                                         cancelTxt="取消"
                                         :chooseTagValue="sex"
                        ></nut-actionsheet>
                        <li @click="switchPicker('isVisible1')"><span>出生日期</span><span class="rt"><a
                                href="#"><img src="img/next.png"></a></span><span
                                class="rt">{{date}}</span></li>
                        <nut-datepicker
                                :is-visible="isVisible1"
                                type="date"
                                title="请选择日期"
                                :is-show-chinese="true"
                                @close="switchPicker('isVisible1')"
                                @choose="setChooseValue"
                                :defaultValue="date"
                                startDate="1900-01-01"
                        >
                        </nut-datepicker>
                        <li @click="dialogShow1=true"><span>住址</span><span class="rt"><a
                                href="#"><img src="img/next.png"></a></span><span
                                class="rt">{{oldman.userAddr}}</span></li>
                        <nut-dialog title="修改住址" :visible="dialogShow1" @ok-btn-click="userAddr"
                                    @cancel-btn-click="reloaduseraddr" @close="dialogShow1=false">
                            <nut-textinput
                                    v-model="oldman.userAddr"
                                    placeholder="请输入内容"
                                    :clearBtn="true"
                                    :disabled="false"
                            />
                        </nut-dialog>
                        <li @click="dialogShow2=true"><span>电话</span><span class="rt"><a
                                href="#"><img src="img/next.png"></a></span><span
                                class="rt">{{oldman.userPhone}}</span></li>
                        <nut-dialog title="修改电话" :visible="dialogShow2" @ok-btn-click="userPhone"
                                    @cancel-btn-click="reloadphone" @close="dialogShow2=false">
                            <nut-textinput
                                    v-model="oldman.userPhone"
                                    placeholder="请输入内容"
                                    :clearBtn="true"
                                    :disabled="false"
                            />
                        </nut-dialog>
                    </ul>
                </div>
            </li>
        </ol>
    </div>
</template>

<script>
    import {getMyoldman, updateUserinfo} from '../api/getMyoldman'
    import server from "../config/server";

    export default {
        name: "Mansetting",
        created() {
            getMyoldman(sessionStorage.getItem('openId')).then(res => {
                this.myoldman = res.data.data
                this.oldman = this.myoldman[sessionStorage.getItem("ind") ? sessionStorage.getItem("ind") : 0]
                this.oldman2.userName = this.oldman.userName
                this.oldman2.userPhone = this.oldman.userPhone
                this.oldman2.userAddr = this.oldman.userAddr
                this.formData.userId = this.oldman.userId
                this.sex = this.getSex(this.oldman.userSex)
                this.date = new Date(this.oldman.userBirthday).Format('yyyy-MM-dd')
            })
        },
        filters: {
            getAge(value) {
                if (value == null) return 0;
                let birthdays = new Date(value.replace(/-/g, '/'));
                let d = new Date();
                let age =
                    d.getFullYear() -
                    birthdays.getFullYear() -
                    (d.getMonth() < birthdays.getMonth() ||
                    (d.getMonth() == birthdays.getMonth() &&
                        d.getDate() < birthdays.getDate())
                        ? 1
                        : 0);
                return age;
            },
            getSex(value) {
                if (value == '1') {
                    return '男';
                } else {
                    return '女';
                }
            }
        },
        data() {
            return {
                dialogShow: false,
                dialogShow1: false,
                dialogShow2: false,
                oldman2: {},
                formData: {
                    userId: '',
                },
                myoldman: [{}],
                oldman: null,
                url: server.baseURL + '/MyInfo/uploadImages',
                sex: '',
                isVisible: false,
                isVisible1: false,
                date: '',
                menuItems: [
                    {
                        'name': '男',
                        'value': 1
                    },
                    {
                        'name': '女',
                        'value': 0
                    }
                ]
            }
        },
        methods: {
            getSex(value) {
                if (value == '1') {
                    return '男';
                } else {
                    return '女';
                }
            },
            onStart() {
                console.log('上传开始');
            },
            onSuccess() {
                this.reload()
            },
            onFail() {
                alert('上传失败！');
            },
            onProgress(file, loaded, total) {
                console.log('上传进度:' + parseInt((100 * loaded) / total) + '%');
            },
            onPreview(file) {
                this.previewImg = file;
            },
            showMsgFn(msg) {
                alert(msg);
            },
            switchActionSheet() {
                console.log(this.isVisible)
                this.isVisible = !this.isVisible;
            },

            chooseItem(itemParams) {
                this.sex = itemParams.name;
                updateUserinfo(this.oldman.userId, 'field=userSex&value=' + itemParams.value)
            },
            switchPicker(param) {
                this[`${param}`] = !this[`${param}`];
            },
            setChooseValue(param) {
                this.date = param[3];
                updateUserinfo(this.oldman.userId, 'field=userBirthday&value=' + param[3])
            },
            userAddr() {
                this.dialogShow1 = false
                updateUserinfo(this.oldman.userId, 'field=userAddr&value=' + this.oldman.userAddr)
            },
            userPhone() {
                this.dialogShow2 = false
                updateUserinfo(this.oldman.userId, 'field=userPhone&value=' + this.oldman.userPhone)
            },
            userName() {
                this.dialogShow = false
                updateUserinfo(this.oldman.userId, 'field=userName&value=' + this.oldman.userName)
            },
            reloadusername() {
                this.oldman.userName = this.oldman2.userName
            },
            reloadphone() {
                this.oldman.userPhone = this.oldman2.userPhone
            },
            reloaduseraddr() {
                this.oldman.userAddr = this.oldman2.userAddr
            },
        }

    }

</script>

<style scoped>

</style>