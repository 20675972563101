<template>
    <div id="app">
        <div v-if="oldman">
<!--            <div class="header w100">-->
<!--                <div class="title"><span>{{oldman.userName}}日志</span></div>-->
<!--            </div>-->

            <div class="clear"></div>

            <div class="w100 bgf">
                <div v-bind:key="item.dt" v-for="item in oldman.dt"  style="margin-top: 10px;margin-bottom: 40px">
                    <div class="title1">{{item.dt}}</div>
                    <div class="list_day2">
                        <ul>
                            <li v-bind:key="it.monitorLogId" v-for="it in item.db">
                                <div class="pc"><img src="img/pc.png" width="50%"></div>
                                <div class="txt1">
                                    <span style="color:#888;">{{new Date(it.monitorTime.replace(/-/g, '/')).Format("hh:mm")}}</span>
                                    <span>{{it.logContent}}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="clear"></div>
            <div class="bk12"></div>

        </div>
    </div>
</template>

<script>
    import {getMyoldmanInfo, getLogInfo, getMyoldman} from '../api/getMyoldman'

    export default {
        name: "Loginfo",
        created() {
            /* 原来用getMyoldmanInfo取老人相关数据，一是可能有多个老人，二是包含在卧室时长等数据，导致速度比较慢。
               改成用getMyoldman，只取当前一个老人的基础信息，速度更快。
            getMyoldmanInfo(sessionStorage.getItem('openId'), this.eDate).then(res => {
                this.myoldman = res.data.data
                this.oldman=this.myoldman[sessionStorage.getItem("ind")?sessionStorage.getItem("ind"):0]
                for (var i = 0; i < this.myoldman.length; i++) {
                    this.getLogData(i, this.myoldman[i].userId);
                }
            })*/
            getMyoldman(sessionStorage.getItem('openId')).then(res => {
                this.myoldman = res.data.data
                this.oldman = this.myoldman[sessionStorage.getItem("ind") ? sessionStorage.getItem("ind") : 0]
                this.getLogData(this.oldman.userId)
            })
        },
        data() {
            return {
                myoldman: [{}],
                bT: false,
                oldman:null,
            }
        },
        methods: {
            /* 原来用getMyoldmanInfo取老人相关数据，因为有多个老人，所以需要对老人进行循环。
               改成用getMyoldman，只取当前一个老人，不需要循环。
            getLogData(i, userId) {
                getLogInfo(userId).then(res => {
                    var data = res.data.data;
                    var logdt = new Array();
                    var old;
                    var arr = new Array();
                    for (let i = 0; i < data.length; i++) {
                        arr.push(data[i]);
                        old = new Date(data[i].monitorTime.replace(/-/g, '/')).Format("MM月dd日 W");
                        var ne = i < data.length - 1 ? new Date(data[i + 1].monitorTime.replace(/-/g, '/')).Format("MM月dd日 W") : "";
                        if (old != ne) {
                            logdt.push({'dt': old, 'db': arr});
                            arr = new Array();
                        }
                    }
                    this.$set(this.myoldman[i], 'dt', logdt)
                })
            },*/
            getLogData(userId) {
                getLogInfo(userId).then(res => {
                    var data = res.data.data;
                    var logdt = new Array();
                    var old;
                    var arr = new Array();
                    for (let i = 0; i < data.length; i++) {
                        arr.push(data[i]);
                        old = new Date(data[i].monitorTime.replace(/-/g, '/')).Format("MM月dd日 W");
                        var ne = i < data.length - 1 ? new Date(data[i + 1].monitorTime.replace(/-/g, '/')).Format("MM月dd日 W") : "";
                        if (old != ne) {
                            logdt.push({'dt': old, 'db': arr});
                            arr = new Array();
                        }
                    }
                    this.$set(this.oldman, 'dt', logdt)
                })
            },
        },
    }
</script>

<style scoped>

</style>