<template>
    <div id="app">
        <div v-if="oldman">
            <!--            <div class="header w100">-->
            <!--                <div class="title"><span>{{oldman.userName}}近一周</span></div>-->
            <!--            </div>-->
            <div class="main-box" v-if="LifeAnalysis">
                <span style="font-weight: bold">概述</span><br/>
                {{LifeAnalysis.overview}}
            </div>
            <!--<div class="main-box" v-if="LifeAnalysis">
                <span style="font-weight: bold">建议</span><br/>
                {{LifeAnalysis.suggest}}
            </div>-->
            <div class="main-box">
                <ve-line :data="filldata(oldman.myGroupInfo,'sleep')" :settings="settings.sleep" height="250px"
                         :colors="colors" :extend="percentExtend"></ve-line>
            </div>
            <div class="main-box">
                <ve-line :data="filldata(oldman.myGroupInfo,'cook')" :settings="settings.cook" height="250px"
                         :colors="colors" :extend="percentExtend"></ve-line>
            </div>
            <div class="main-box">
                <ve-line :data="filldata(oldman.myGroupInfo,'out')" :settings="settings.out" height="250px"
                         :colors="colors" :extend="percentExtend"></ve-line>
            </div>
            <!--------------------------
            <div class="main-box">
                <ve-line :data="batheDate[oldman.userId]" :settings="chartSettings" height="250px" :colors="colors"></ve-line>
            </div>
            <div class="main-box">
                <ve-line :data="filldata(oldman.myGroupInfo,'shit')" :settings="settings.shit" height="250px" :colors="colors"></ve-line>
            </div>
            ---------------->
            <div class="main-box">
                <ve-line :data="filldata(oldman.myGroupInfo,'urine')" :settings="settings.urine" height="250px"
                         :colors="colors" :extend="percentExtend"></ve-line>
            </div>
            <br>
            <br>
        </div>
    </div>
</template>

<script>
    import {getGroupInfo, getBatheList, getUserLifeAnalysis} from '../api/getMyoldman'
    import VeLine from 'v-charts/lib/line.common'
    import 'v-charts/lib/style.css'


    export default {
        components: {VeLine},
        name: "Week",
        created() {
            getGroupInfo(sessionStorage.getItem('openId')).then(res => {
                console.log("getGroupInfo:{}",res)
                this.myoldman = res.data.data;
                this.oldman = this.myoldman[sessionStorage.getItem("ind") ? sessionStorage.getItem("ind") : 0]
                console.log("this.oldman:",this.oldman)
                getUserLifeAnalysis(this.oldman.userId).then(res => {
                    this.LifeAnalysis = res.data.data[0]
                })
                if (this.myoldman != null) {
                    for (var i = 0; i < this.myoldman.length; i++) {
                        this.getBathe(this.myoldman[i].userId);
                    }
                }
            });

        },
        data() {
            return {
                myoldman: [{}],
                settings: {},
                batheDate: {},
                colors: ['#fc783a'],
                oldman: null,
                LifeAnalysis:{},
                chartSettings: {
                    labelMap: {
                        'sums': '洗澡周期(天数)',
                    },
                    legendName: {
                        'sums': '洗澡周期(天数)',
                    }
                },
                percentExtend: {
                    series: {
                        label: {
                            show: true,
                        }
                    },
                    legend: {
                        bottom: 10
                    }
                },
            }
        },
        methods: {
            getBathe(userId) {
                var dt = [{}];
                var tmp = new Array();
                var dd = {
                    columns: ['dt', 'sums'],
                    rows: tmp
                }
                getBatheList(userId).then(res => {
                    console.log("getBatheList:{}",res)
                    dt = res.data.data;
                    // if (new Date().Format('yyyy-MM-dd') != dt[dt.length - 1]) {
                    //     dt.push(new Date().Format("yyyy-MM-dd"));
                    // }
                    for (var i = 0; i < dt.length; i++) {
                        if (i >= 1) {
                            var t = Math.round((new Date(dt[i]).getTime() - new Date(dt[i - 1]).getTime()) / (24 * 3600 * 1000));
                            tmp.push({'dt': new Date(dt[i]).Format("MM-dd"), 'sums': t})
                        } else {
                            tmp.push({'dt': new Date(dt[i]).Format("MM-dd"), 'sums': 0})
                        }
                    }
                    tmp.shift();
                    dd.rows = tmp;
                    this.$set(this.batheDate, userId, dd);
                })
            },
            filldata(list, type) {
                if (list) {
                    var myDate = new Date();
                    var cDate = new Date();
                    var arr = new Array();
                    var i;
                    for (i = 6; i >= 0; i--) {
                        var is = false;
                        cDate.setTime(myDate.getTime() - (i + 1) * 24 * 60 * 60 * 1000);
                        var dt = cDate.Format("MM-dd");
                        for (var t in list) {
                            if (dt == list[t].actionDate && type == list[t].actionId) {
                                arr.push({
                                    'dt': dt,
                                    'sums': type == 'sleep' ? Math.round(list[t].sums / 60 * 10) / 10 : list[t].sums,
                                    'ct': list[t].counts
                                });
                                is = true;
                            } else {
                                continue;
                            }
                        }
                        if (!is) {
                            arr.push({'dt': dt, 'sums': 0, 'ct': 0});
                        }
                    }
                    var dd = {
                        columns: ['dt', 'sums'],
                        rows: arr
                    }
                    var chartSettings = {
                        labelMap: {
                            'sums': '时长',
                            'ct': '次数'
                        },
                        legendName: {
                            'sums': '时长',
                            'ct': '次数'
                        }
                    }
                    var title = {
                        'sleep': '睡眠时长(小时)',
                        'parlor': '客厅时长(分钟)',
                        'shit': '大便次数',
                        'urine': '卫生间次数',
                        'cook': '厨房时长(分钟)',
                        'bathe': '洗澡时长(分钟)',
                        'out': '外出时长(分钟)'
                    };
                    chartSettings.labelMap.sums = chartSettings.legendName.sums = title[type];
                    if (title[type].indexOf('次数') > 0) {
                        dd.columns = ['dt', 'ct'];
                        chartSettings.labelMap.ct = chartSettings.legendName.ct = title[type];
                    }
                    this.settings[type] = chartSettings;
                    return dd;
                }
            }
        }
    }
</script>