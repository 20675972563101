<template>
    <div class="box-white">
        <div class="main-boxx">
            <div @click="switchPicker('isVisible')" class="clearfix">
                <div style="float: right;margin-right: 4%;margin-top: 15px;margin-bottom: 15px">{{eDate}}▼<span>
                    <nut-datepicker :defaultValue="eDate"
                                    :is-show-chinese="true"
                                    :is-visible="isVisible"
                                    @choose="setChooseValue"
                                    title="请选择日期"
                                    type="date"
                    >
                    </nut-datepicker>
                    </span>
                </div>
            </div>
        </div>
        <hr>
        <div class="main-boxx  list" style="margin-bottom: 2rem;zoom:0.7" v-if="oldman">
            <ul>
                <li class="clearfix" v-bind:key="item.actionDataId" v-for="(item,i) in oldman.data">
                    <div class="line" v-if="i<oldman.data.length-1"></div>
                    <span class=" time">{{item.actionBegTime|getHM}}</span><span class=" dice"><img
                        v-bind:src="img[i%6]"></span>
                    <span class=" time-information">
                        <div class="clearfix">
                            <!--为了排版好看，设置了一个高度为10px的空行-->
                        <div class="left"><div style="height: 10px;"></div><span class="font13">{{acTion[item.actionId][0]}}</span><br><span
                                class="font-gray font10" v-if="item.actionId=='sleepm'">正常时间</span>
                        <span
                                class="font-gray font10" v-else>{{item.actionBegTime|getHM}}-{{item.actionEndTime|getHM}}({{getL(item)|getDuration}})</span></div>
                        <div class="right information-pic"><img v-bind:src="acTion[item.actionId][1]"></div>
                        </div>
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import {getMyoldmanInfo} from '../api/getMyoldman'

    export default {
        name: "Daylife",
        data() {
            return {
                eDate: window.eDate ? window.eDate : new Date().Format("yyyy-MM-dd"),
                myoldman: {},
                isVisible: false,
                oldman:null,
                acTion: {
                    bathe: ['洗澡', 'images/icon-xz1@2x.png'],
                    cook: ['厨房活动', 'images/icon-zf@2x.png'],
                    cook1: ['厨房(早饭)', 'images/icon-zf@2x.png'],
                    cook2: ['厨房(午饭)', 'images/icon-zf@2x.png'],
                    cook3: ['厨房(晚饭)', 'images/icon-zf@2x.png'],
                    out: ['外出', 'images/icon-wc1@2x.png'],
                    shit: ['大便', 'images/icon-db@2x.png'],
                    sleep: ['睡觉', 'images/icon-qc@2x.png'],
                    sleepm: ['起床', 'images/icon-qc@2x.png']
                },
                img: ['images/icon-orange@2x.png', 'images/icon-blue@2x.png', 'images/icon-green@2x.png', 'images/icon-purple@2x.png', 'images/icon-pink@2x.png', 'images/icon-yellow@2x.png']
            }
        },
        created() {
            this.init();
        },
        filters: {
            getHM(datetime) {
                if (datetime == null) return ''
                var d = new Date(datetime.replace(/-/g, '/'));
                return d.Format('hh:mm')
            },
            getDuration(second) {
                if (second<0) return '0分钟';
                var hours = Math.floor(second / 3600);
                var minutes = Math.floor((second % 3600) / 60);
                var duration = '';
                if (hours >= 1) {
                    duration = hours + "小时";
                }
                duration = duration + minutes + "分钟";
                return duration;
            }
        },
        methods: {
            init() {
                window.eDate = this.eDate;
                getMyoldmanInfo('111', this.eDate).then(res => {
                    this.myoldman = res.data.data
                    if (this.myoldman == null) {
                        window.ts.Toast.text("系统未查到您的传感器设备！");
                        return;
                    }
                    this.oldman=this.myoldman[sessionStorage.getItem("ind")?sessionStorage.getItem("ind"):0]
                    for (var i = 0; i < this.myoldman.length; i++) {
                        this.myoldman[i].data = this.getdata(this.myoldman[i].tdActionDataList, ['sleep', 'cook', 'out', 'bathe'])
                    }
                })
            },
            getdata(list, type) {
                var data = new Array();
                var i;
                var needSord = false;
                for (i in list) {
                    if ((typeof type == 'string' && list[i].actionId == type) || type.includes(list[i].actionId)) {
                        if (list[i].actionId == 'sleep' && list[i].actionEndTime != null && new Date(list[i].actionEndTime.replace(/-/g, '/')).Format('hh') <= '10' && needSord == false) {
                            list[i].actionBegTime = list[i].actionEndTime;
                            list[i].actionId = 'sleepm';
                            needSord = true;
                        } else if (list[i].actionId == 'cook') {
                            var h = new Date(list[i].actionBegTime.replace(/-/g, '/')).getHours();
                            if (h >= 5 && h <= 8)
                                list[i].actionId = 'cook1'
                            else if (h >= 10 && h <= 13)
                                list[i].actionId = 'cook2'
                            else if (h >= 16 && h <= 19)
                                list[i].actionId = 'cook3'
                        }
                        data.push(list[i]);
                    }
                }
                if (needSord) {
                    data.sort((a, b) => {
                        return a.actionBegTime > b.actionBegTime ? 1 : -1
                    })
                }
                return data
            },
            getL(dt) {
                if (dt.actionDuration == null) {
                    var e = new Date().getTime();
                    var b = new Date(dt.actionBegTime.replace(/-/g, '/')).getTime();
                    return Math.floor((e - b) / 1000);
                } else return dt.actionDuration;
            },
            switchPicker(param) {
                this[`${param}`] = !this[`${param}`];
            },
            setChooseValue(param) {
                this.eDate = param[3];
                this.init()
            },
        }
    }
</script>

<style scoped>
    html {
        font-size: 62.5%;
        background: #f8f8f8;
    }

    body {
        color: #333;
        background: #f8f8f8;
        font-size: 1.2rem;
        line-height: 150%;
        font-family: Arial, Verdana, Helvetica, sans-serif;
    }

    @media only screen and (max-width: 360px) and (min-width: 320px) {
        html {
            font-size: 68.75% !important;
        }
    }

    @media only screen and (max-width: 400px) and (min-width: 360px) {
        html {
            font-size: 75% !important;
        }
    }

    @media only screen and (max-width: 480px) and (min-width: 400px) {
        html {
            font-size: 81.25% !important;
        }
    }

    @media only screen and (max-width: 560px) and (min-width: 480px) {
        html {
            font-size: 87.5% !important;
        }
    }

    @media only screen and (max-width: 640px) and (min-width: 560px) {
        html {
            font-size: 93.75% !important;
        }
    }

    @media only screen and (min-width: 640px) {
        html {
            font-size: 100% !important;
        }
    }

    body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
        margin: 0;
        padding: 0
    }

    table {
        border-collapse: collapse;
        border-spacing: 0
    }

    fieldset, img {
        border: 0
    }

    address, caption, cite, code, dfn, em, th, var {
        font-style: normal;
        font-weight: normal
    }

    ol, ul {
        list-style: none
    }

    caption, th {
        text-align: left
    }

    h1, h2, h3, h4, h5, h6 {
        font-size: 100%;
        font-weight: normal
    }

    q:before, q:after {
        content: ''
    }

    abbr, acronym {
        border: 0;
        font-variant: normal
    }

    sup {
        vertical-align: text-top
    }

    sub {
        vertical-align: text-bottom
    }

    input, textarea, select {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit
    }


    .left {
        float: left;
    }

    .right {
        float: right;
    }

    .clear {
        clear: both;
    }

    a:link {
        color: #333;
    }

    a:hover {
        text-decoration: none;
    }

    .clearfix {
    }

    .clearfix:before, .clearfix:after {
        display: table;
        content: "";
        line-height: 0;
    }

    .clearfix:after {
        clear: both;
    }

    .font10 {
        font-size: 1rem;
    }

    .font11 {
        font-size: 1.1rem;
    }

    .font12 {
        font-size: 1.2rem;
    }

    .font13 {
        font-size: 1.3rem;
    }

    .font14 {
        font-size: 1.4rem;
    }

    .font15 {
        font-size: 1.5rem;
    }

    .font20 {
        font-size: 2rem;
    }

    .font-red {
        color: #ff0000;
    }

    .font-green {
        color: #aabe03;
    }

    .font-gray {
        color: #999;
    }

    .font-gray1 {
        color: #666;
    }

    .font-black {
        color: #333;
    }

    .margin-top20 {
        margin-top: 2rem;
    }

    .margin-top15 {
        margin-top: 1.5rem;
    }

    .margin-top10 {
        margin-top: 1rem;
    }

    .margin-top5 {
        margin-top: 0.5rem;
    }

    .margin-bottom15 {
        margin-bottom: 1.5rem;
    }

    .margin-bottom10 {
        margin-bottom: 1rem;
    }

    .header {
        position: relative;
        width: 100%;
        height: 4rem;
        color: #fff;
        text-align: center;
        font-size: 1.5rem;
        line-height: 4rem;
        background: -webkit-linear-gradient(left, #fcab52, #fc783a); /* Safari 5.1 - 6.0 */

        background: -o-linear-gradient(left, #fcab52, #fc783a); /* Opera 11.1 - 12.0 */

        background: -moz-linear-gradient(left, #fcab52, #fc783a); /* Firefox 3.6 - 15 */

        background: linear-gradient(to left, #fcab52, #fc783a); /* 标准*/
    }

    .box-white {
        background: #fff;
        color: #333;
        font-size: 1.2rem;
        line-height: 110%;
        font-family: Arial, Verdana, Helvetica, sans-serif;
    }

    .icon-back {
        display: block;
        position: absolute;
        z-index: 10;
        top: 30%;
        left: 1rem;
        background-size: 7%;
        width: 46%;
        height: 46%;
    }

    .banner {
        position: relative;
    }

    .img-header {
        position: absolute;
        top: 0.5rem;
        left: 1.5rem;
        color: #fff;
        width: 33%;
        font-size: 1.3rem;
    }

    .img-header span {
        display: inline-block;
        vertical-align: middle;
    }

    .header-pic {
        width: 26%;
        margin-right: 0.3rem;
    }

    .note-banner {
        position: absolute;
        top: 0.6rem;
        left: 50%;
        margin-left: -17%;
        text-align: center;
        width: 34%;
        padding: 0.1rem 1rem;
        border: 1px solid #fff;
        border-radius: 20px;
        color: #fff;
    }

    .num-red {
        background: #ff0000;
        color: #fff;
        font-size: 1rem;
        text-align: center;
        width: 1.2rem;
        height: 1.2rem;
        line-height: 1.2rem;
        border-radius: 50%;
        display: block;
        position: relative;
        top: -0.4rem;
        right: -0.8rem;
    }

    .date {
        font-size: 1.4rem;
        font-weight: bold;
    }

    .icon-down {
        display: inline-block;
        width: 10%;
    }

    .title-box {
        padding: 2% 4%;
        border-bottom: 1px solid #e6e7e6;
    }

    .icon-zx {
        display: inline-block;
        width: 5%;
        margin: 0 0.5rem 0 0;
        position: relative;
        top: 0.3rem;
    }

    .zx-box {
        margin: 1.5rem auto;
        text-align: center;
    }

    .zx-box li {
        float: left;
        text-align: center;
        font-weight: bold;
        width: 33%;
        font-size: 1.3rem;
        border-left: 1px solid #e6e7e6;
    }

    .zx-box li:first-child {
        border-left: none;
    }

    .zx-box-note {
        color: #999;
        font-size: 1.1rem;
        font-weight: 100;
    }

    .title-small {
        color: #999;
        font-size: 1rem;
        margin: 0.5rem 0;
    }

    .date-table a {
        text-decoration: none;
    }

    .wc-box {
        width: 49%;
    }

    .wc-box .title-box, .zf-box .title-box {
        padding: 4% 0 4% 8%;
    }

    .zf-box {
        width: 49%;
    }

    .icon-wc {
        display: inline-block;
        width: 10%;
        margin: 0 0.5rem 0 0;
        position: relative;
        top: 0.3rem;
    }

    .icon-error {
        display: inline-block;
        width: 8%;
        margin: 0 0 0 0.5rem;
        position: relative;
        top: 0.1rem;
    }

    .three-list {
        width: 12%;
    }

    .wc-box-main {
        margin: 1rem 0 1rem 8%;
        padding-left: 8%;
        height: 6.5rem
    }

    .wc-box-main li {
        list-style: disc;
        color: #999;
        margin: 0.5rem 0;
        font-size: 1.1rem;
    }

    .kt-box, .eb-box, .xy-box {
        width: 32%;
    }

    .kt-box .wc-box-main, .eb-box .wc-box-main, .xy-box .wc-box-main {
        height: 4.3rem;
    }

    .kt-box .title-box, .eb-box .title-box, .xy-box .title-box {
        padding: 8% 0 8% 12%;
    }

    .icon-kt {
        display: inline-block;
        width: 15%;
        margin: 0 0.5rem 0 0;
        position: relative;
        top: 0.3rem;
    }

    .eb-box {
        margin-left: 2%;
    }

    .kt-box .wc-box-main, .eb-box .wc-box-main, .xy-box .wc-box-main {
        padding-left: 2%;
    }

    .kt-box .wc-box-main, .eb-box .wc-box-main li, .xy-box .wc-box-main li {
        list-style: none;
    }

    .pic {
        padding: 1.5rem 0 5rem;
    }

    .foot-nav {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        border-top: 1px solid #e6e7e6;
        padding: 0.5rem 0 0.2rem;
        text-align: center;
        background: #fff;
    }

    .foot-nav li {
        display: inline-block;
        width: 30%;
        text-align: center;
    }

    .foot-nav li a {
        color: #b1b1b1;
        font-size: 1rem;
        text-decoration: none;
    }

    .icon-footer {
        width: 20%;
        margin: 0 auto;
    }

    .foot-nav li.active a {
        color: #fc793a;
    }

    hr {
        height: 1px;
        border: none;
        border-bottom: 1px solid #e6e7e6;
    }

    .time {
        font-size: 1.5rem;
        color: #666;
        width: 15%;
        text-align: center;
        margin-right: 1%;
    }

    .dice {
        width: 6%;
    }

    .time-information {
        width: 60%;
        background: #fff6ef;
        padding: 0.7rem 1.2rem;
        margin-left: 6%;
    }

    .list li {
        margin: 1rem;
        position: relative;
    }

    .list li span {
        display: inline-block;
        vertical-align: middle;
    }

    .information-pic {
        width: 20%;
    }

    .line {
        position: absolute;
        top: 76%;
        left: 18.5%;
        width: 2px;
        height: 3.5rem;
        background: #ffd4b5;
    }

    .box-three {
        margin-top: 1rem;
    }

    .list {
        margin-bottom: 0;
        padding-bottom: 1rem;
    }

    @media only screen and (min-width: 500px) {
        .icon-back {

            top: 30%;
            background-size: 6%;
            width: 30%;
            height: 50%;
        }

        .line {
            height: 4rem;
        }
    }
</style>