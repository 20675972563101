<template>
    <div id="app">
        <ol>
            <li v-if="oldman">
                <!--                <div class="header w100">-->
                <!--                    <div class="title"><span>我的</span></div>-->
                <!--                </div>-->

                <div class="clear"></div>
                <div class="bk12"></div>
                <div class="box_90" onclick="window.location='/#/Mansetting'">
                    <div style="width:18%; float:left;margin-left:5%">
                        <img v-if="oldman.bigPhotoUrl" :src="oldman.bigPhotoUrl" width="60">
                        <img v-else src="images/logo.png" width="60">
                    </div>
                    <div style="width:68%; float:left; margin-left:5%;line-height:2rem;color:#fff;"><span
                            style="font-size:1rem;"><strong>{{oldman.userName}}</strong></span><span>{{oldman.userSex | getSex}}</span><span>{{oldman.userBirthday | getAge}}岁</span>
                    </div>
                    <div style="width:5%; float:right;margin-left:5%;"><img src="img/next.png"></div>

                    <div style="width:68%; margin-left:28%;line-height:2rem;color:#fff;"><span
                            style="font-size:0.8rem;">{{oldman.userAddr}}</span>
                    </div>
                </div>

                <div class="clear"></div>
                <div class="bk12"></div>

                <div class="list_wd">
                    <ul>
                        <!--<li onclick="window.location='/#/Renew'"><span><img src="img/yx.png" width="25px"></span><span><a href="#">服务有效期</a></span><span class="rt"><a
                                href="#"><img src="img/next.png"></a></span><span class="rt">2021年12月31日</span></li>-->
<!--                        <li onclick="window.ts.Toast.text('该功能正在开发中。。。');"><span><img src="img/bd.png" width="25px"></span><span><a href="#">账号绑定</a></span><span class="rt"><a-->
<!--                                href="#"><img src="img/next.png"></a></span><span class="rt">已绑定</span></li>-->
                        <!--<li><span><img src="img/bd.png" width="25px"></span><span><a href="#">账号绑定</a></span><span class="rt"><a
                                href="#"><img src="img/next.png"></a></span><span class="rt">已绑定</span></li>-->
                        <!--<li onclick="window.location='/#/Servicetime'"><span><img src="img/yx.png" width="25px"></span><span><a href="#">服务有效期</a></span><span class="rt"><a
                                href="#"><img src="img/next.png"></a></span></li>-->
                        <li onclick="window.location='/#/Device'"><span><img src="img/bd.png" width="25px"></span><span><a href="#">我的设备</a></span><span class="rt"><a
                                href="#"><img src="img/next.png"></a></span></li>
                        <li onclick="window.location='/#/Setting'"><span><img src="img/sz.png"></span><span><a href="#">设置</a></span><span class="rt"><a
                                href="#"><img src="img/next.png"></a></span></li>
                        <li onclick="window.location='/#/Introduce'"><span><img src="img/gy.png"></span><span><a href="#">关于</a></span><span class="rt"><a
                                href="#"><img src="img/next.png"></a></span></li>
                        <!--<li onclick="window.location='/#/Introduce'"><span><img src="img/yx.png" width="25px"></span><span><a href="#">平台介绍</a></span><span class="rt"><a
                                href="#"><img src="img/next.png"></a></span></li>-->
                    </ul>
                </div>
            </li>
        </ol>
    </div>
</template>

<script>
    import {getMyoldman} from '../api/getMyoldman'

    export default {
        name: "Myoldman",
        created() {
            getMyoldman(sessionStorage.getItem('openId')).then(res => {
                this.myoldman = res.data.data
                this.oldman=this.myoldman[sessionStorage.getItem("ind")?sessionStorage.getItem("ind"):0]
                //window.ts.Toast.text("userid:"+this.oldman.userId);
            })
        },
        filters: {
            getAge(value) {
                if (value == null) return 0;
                let birthdays = new Date(value.replace(/-/g, '/'));
                let d = new Date();
                let age =
                    d.getFullYear() -
                    birthdays.getFullYear() -
                    (d.getMonth() < birthdays.getMonth() ||
                    (d.getMonth() == birthdays.getMonth() &&
                        d.getDate() < birthdays.getDate())
                        ? 1
                        : 0);
                return age;
            },
            getSex(value) {
                if (value == '1') {
                    return '男';
                } else {
                    return '女';
                }
            }
        },
        data() {
            return {
                myoldman: [{}],
                oldman:null,
            }
        }
    }

</script>

<style scoped>

</style>